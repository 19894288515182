<template>
  <div class="table-container" v-bind:class="classObject">
    <!-- Render table only if orderList is not empty -->
    <b-table
      v-if="orderList.length > 0"
      :items="orderList"
      :fields="fields"
      hover
      responsive
      class="mt-3 b-datatable cursor-pointer"
    >
      <template v-slot:cell(customer)="data">
        {{ data.item.customer?.first_name || data.item.Customer_id }} {{ data.item.customer?.last_name }}
      </template>

      <template v-slot:cell(status)="data">
        <div class="d-flex align-items-center" v-if="data.item.status !== undefined">
          <div v-if="data.item.status === null" class="pending order-status">Pending</div>
          <div v-else-if="data.item.status === 'approved'" class="approved order-status">Approved</div>
          <div v-else-if="data.item.status === 'disapproved'" class="disapproved order-status">Disapproved</div>
          <div v-else-if="data.item.status === 'partially refunded'" class="partial-refund order-status">Partial refunded</div>
          <div v-else-if="data.item.status === 'fully refunded' || data.item.status === 'refunded'" class="refund order-status">Refunded</div>
        </div>
      </template>

      <template v-slot:cell(suspicious_activities)="data">
        <span v-if="data.item.suspicious_activities[0]">
          {{
            data.item.suspicious_activities[0].Risk_level.charAt(0).toUpperCase() +
            data.item.suspicious_activities[0].Risk_level.slice(1).toLowerCase()
          }}
        </span>
        <span v-else>-</span>
      </template>

      <template v-slot:cell(actions)="data">
        <div v-if="data.item.approved === null">
          <div class="dropdown">
            <button class="btn btn-default" type="button" data-toggle="dropdown">
              &#x2026;
            </button>
            <ul class="dropdown-menu">
              <li @click="verifyOrder(data.item)">Approve</li>
              <li @click="unVerifyOrder(data.item)">Disapprove</li>
            </ul>
          </div>
        </div>
        <span v-else>N/A</span>
      </template>
    </b-table>

     <!-- Show message when orderList is empty and filter is applied -->
     <div v-if="showLoader === 0 && orderList.length === 0 && isFilterApplied" class="row m-0 h-100 d-flex flex-column justify-content-center align-items-center">
      <div class="empty-list col-md-4">
        <div class="empty-icon">
          <img src="../../assets/images/shock-sjield/bag-light.png">
        </div>
        <p class="mt-2 mb-0">No Results Found</p>
        <span class="text-center">Try adjusting your filters or clearing them.</span>
      </div>
    </div>

    <!-- Show default empty message when no filters and no orders -->
    <div v-if="showLoader === 0 && orderList.length === 0 && !isFilterApplied" class="row m-0 h-100 d-flex flex-column justify-content-center align-items-center">
      <div class="empty-list col-md-4">
        <div class="empty-icon">
          <img src="../../assets/images/shock-sjield/bag-light.png">
        </div>
        <p class="mt-2 mb-0">No Orders Yet?</p>
        <span class="text-center">Add products to your store and start selling to see orders here.</span>
      </div>
    </div>

  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "OrderTable",

  data() {
    return {
      fields: [
        { key: 'order_number', label: 'Order Number', sortable: true },
        { key: 'customer', label: 'Customer', sortable: true },
        { key: 'status', label: 'Status', sortable: true , },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'shopName', label: 'Shop Name', sortable: true },
        { key: 'suspicious_activities', label: 'CO Risk Level', sortable: false },
        { key: 'created_at', label: 'Created At', sortable: true, formatter: (value) => this.$options.filters.moment(value, "MMM D, YYYY") },
        { key: 'actions', label: 'Actions' },
      ],
    };
  },
  props: {
    orderList: {
      type: Array,
    },
    message: Boolean,
    showLoader: Number,
    isFilterApplied: Boolean, // Pass this from the parent component

  },
  methods: {
    verifyOrder(item) {
      this.$swal({
        title: "Are you sure?",
        text: "Do you want to approve this order?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, approve it!",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          var formData = new FormData();
          formData.append("order_id", item.id);
          formData.append("item", JSON.stringify(item));
          formData.append("approved", true);

          axios
            .post(process.env.VUE_APP_API_URL + "approveOrder", formData)
            .then((response) => {
              const statusCode = response.data.statusCode || 500;
              if (statusCode === 200) {
                console.log("Order approved:", item.id);
                this.$root.$emit("update-data");

                this.$swal({
                  title: "Success!",
                  text: "Order has been approved successfully.",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log("Error occurred:", error);

              this.$swal({
                title: "Error!",
                text: "An error occurred while approving the order.",
                icon: "error",
              });
            });
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          this.$swal({
            title: "Cancelled",
            text: "Order approval has been cancelled.",
            icon: "info",
          });
        }
      });
    },

    unVerifyOrder(item) {
      this.$swal({
        title: "Are you sure?",
        text: "Do you want to disapprove this order?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, disapprove it!",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          var formData = new FormData();
          formData.append("order_id", item.id);
          formData.append("approved", false);
          formData.append("item", JSON.stringify(item));

          axios
            .post(process.env.VUE_APP_API_URL + "approveOrder", formData)
            .then((response) => {
              const statusCode = response.data.statusCode || 500;
              if (statusCode === 200) {
                this.$root.$emit("update-data");
                console.log("Order disapproved:", item);

                this.$swal({
                  title: "Success!",
                  text: "Order has been disapproved successfully.",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log("Error occurred:", error);

              this.$swal({
                title: "Error!",
                text: "An error occurred while disapproving the order.",
                icon: "error",
              });
            });
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          this.$swal({
            title: "Cancelled",
            text: "Order disapproval has been cancelled.",
            icon: "info",
          });
        }
      });
    },
  },
  computed: {
    classObject: function() {
      return {
        "reg-table": this.message,
        "reg2-table": !this.message,
      };
    },
  },

  mounted() {},
};
</script>
<style scoped>
#dropdownMenu1 {
  font-weight: 800;
}
#dropdownMenu1:focus {
  border: none !important;
  box-shadow: none !important;
}
.dropdown-menu {
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  top: -7px !important;
  left: -42px !important;
  min-width: 6rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.dropdown-menu li {
  padding-left: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  color: var(--main-black);
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: block;
}
.dropdown-menu li:hover {
  background-color: rgb(241, 240, 240);
}

</style>
